import { css } from '@emotion/react';
import React from 'react';
import colors from '../theme/colors';
import Footer from './Footer';
import Header from './Header';

const Layout = ({
  children,
  showHeader = true,
  showFooter = false,
}: {
  showHeader?: boolean;
  showFooter?: boolean;
  children: React.ReactNode;
}) => {
  if (typeof window !== 'undefined') {
    window.addEventListener('load', function () {
      setTimeout(function () {
        // This hides the address bar:
        window.scrollTo(0, 1);
      }, 0);
    });
  }
  return (
    <div
      css={css`
        width: 100%;
        position: relative;
        background-color: ${colors.background};
      `}
    >
      {showHeader && <Header />}
      <div
        css={css`
          position: relative;
        `}
      >
        <div
          css={css`
            height: calc(100vh - ${showHeader ? 110 : 0}px);
            overflow-y: scroll;
          `}
        >
          {children}
          {showFooter && <Footer />}
        </div>
      </div>
    </div>
  );
};
export default Layout;
