import { css } from '@emotion/react';
import React, { useRef, useState } from 'react';
import { useOnClickOutside } from '../hooks/useOnClickOutside';
import { TABLET } from '../theme/breakpoints';
import { Container } from './Container';
import colors from '../theme/colors';
import { Text } from '../components/Text';
import Icon from '../components/icon/Icon';

type MenuProps = {
  children: React.ReactNode;
  trigger: React.ReactElement;
};

const FullScreenMenu = ({
  children,
  trigger,
  ...rest
}: MenuProps & React.HTMLAttributes<HTMLDivElement>) => {
  const ref = useRef(null);
  useOnClickOutside(ref, () => setVisible(false));
  const [visible, setVisible] = useState(false);

  return (
    <div
      ref={ref}
      css={css`
        position: relative;
        display: inline-block;
      `}
      {...rest}
    >
      <div
        css={css`
          cursor: pointer;
        `}
        onClick={() => setVisible(!visible)}
      >
        {trigger}
      </div>
      <div
        css={css`
          width: 100vw;
          height: 100vh;
          position: fixed;
          left: 0px;
          top: 0px;
          transform: translate(${visible || `10`}0vw);
          transition: transform 0.4s ease-in-out;
          background-color: ${colors.darkGray};
          z-index: 99999;
        `}
        onClick={() => setVisible(false)}
      >
        <Container size="large">
          <Icon
            size={56}
            css={css`
              float: right;
              padding-top: 40px;
              cursor: pointer;
            `}
            icon="Close"
            color="white"
          ></Icon>
          <div
            css={css`
              padding-top: 136px;
              @media (min-width: ${TABLET}px) {
                padding-top: 116px;
              }
              display: flex;
              flex-direction: column;
              text-align: left;
              gap: 10px;
              > a {
                color: white;
                padding: 10px 10px;
                width: max-content;
              }
            `}
          >
            {children}
          </div>
        </Container>
      </div>
    </div>
  );
};

const Item = ({ href, label, onClick }: { href?: string; label: string; onClick?: () => void }) =>
  onClick ? (
    <a
      onClick={onClick}
      css={css`
        cursor: pointer;
      `}
    >
      <Text variant="H3">{label}</Text>
    </a>
  ) : (
    <a
      href={href}
      css={css`
        cursor: pointer;
      `}
    >
      <Text variant="H3">{label}</Text>
    </a>
  );
FullScreenMenu.Item = Item;
export default FullScreenMenu;
