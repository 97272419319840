import { css } from '@emotion/react';
import React from 'react';

type FooterProps = {};

const Footer = ({}: FooterProps) => {
  return (
    <footer
      css={css`
        background-color: black;
        color: white;
        text-align: center;
        width: 100%;
        height: 80px;
        margin-top: 80px;
      `}
    ></footer>
  );
};

export default Footer;
