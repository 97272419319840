import * as React from 'react';
import { css } from '@emotion/react';
import { DESKTOP, TABLET } from '../theme/breakpoints';

export const containerPadding = (fullWidth: boolean = false) => css`
  padding-left: ${fullWidth ? 0 : '20px'};
  padding-right: ${fullWidth ? 0 : '20px'};
  @media (min-width: ${TABLET}px) {
    padding-left: ${fullWidth ? 0 : '40px'};
    padding-right: ${fullWidth ? 0 : '40px'};
  }
  @media (min-width: ${DESKTOP}px) {
    padding-left: ${fullWidth ? 0 : '80px'};
    padding-right: ${fullWidth ? 0 : '80px'};
  }
`;
const containerWidth = (size: 'small' | 'medium' | 'large', fullWidth?: boolean) => {
  if (fullWidth) {
    return css`
      max-width: 1920px;
    `;
  }
  switch (size) {
    case 'small':
      return css`
        max-width: 940px;
      `;
    case 'medium':
      return css`
        max-width: 1100px;
      `;
    case 'large':
      return css`
        max-width: 1400px;
      `;
  }
};
/** Renders its children in a centered container with a specified maximum width. */
export const Container = ({
  size = 'medium',
  fullWidth = false,
  padding = true,
  children,
  ...rest
}: {
  size?: 'small' | 'medium' | 'large';
  padding?: boolean;
  maxWidth?: string;
  fullWidth?: boolean;
} & React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <article
      css={css`
        margin: auto;
        box-sizing: border-box;
        ${padding && containerPadding(fullWidth)}
        ${containerWidth(size, fullWidth)}
      `}
      {...rest}
    >
      {children}
    </article>
  );
};
