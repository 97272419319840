import { css } from '@emotion/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import Icon from '../components/icon/Icon';
import { Text } from '../components/Text';
import { TABLET } from '../theme/breakpoints';
import colors from '../theme/colors';
import FullScreenMenu from '../layout/FullScreenMenu';
import { useUser } from '@auth0/nextjs-auth0';
import { isAdmin } from '../auth/permissions';

type HeaderProps = {};

const Header = ({}: HeaderProps) => {
  const router = useRouter();
  const { user } = useUser();

  return (
    <header
      css={css`
        z-index: 10;
        position: sticky;
        top: 0;
        width: 100%;
        color: white;
        background-color: ${colors.background};
        text-align: center;
        display: flex;
        justify-content: space-between;
        padding: 20px 20px 20px 20px;
        @media (min-width: ${TABLET}px) {
          padding: 40px 100px 20px 100px;
        }
        color: var(--color-Type);
      `}
    >
      <a onClick={() => router.push('/')}>
        <Icon icon="Logo" width={100} />
      </a>
      <div
        css={css`
          display: none;
          gap: 42px;
          @media (min-width: ${TABLET}px) {
            display: flex;
          }
        `}
      >
        <Link href="/cards">
          <a>
            <Text
              variant="Body"
              css={css`
                padding: 4px 8px;
                border-bottom: ${router.pathname === '/cards'
                  ? '1px solid var(--color-Type)'
                  : 'unset'};
              `}
            >
              Cards
            </Text>
          </a>
        </Link>
        <Link href="/collections">
          <a>
            <Text
              variant="Body"
              css={css`
                padding: 4px 8px;
                border-bottom: ${router.pathname === '/collections'
                  ? '1px solid var(--color-Type)'
                  : 'unset'};
              `}
            >
              Collections
            </Text>
          </a>
        </Link>
        <Link href="/my-profile">
          <a>
            <Text
              variant="Body"
              css={css`
                padding: 4px 8px;
                border-bottom: ${router.pathname === '/my-profile'
                  ? '1px solid var(--color-Type)'
                  : 'unset'};
              `}
            >
              My Profile
            </Text>
          </a>
        </Link>
      </div>

      <FullScreenMenu
        css={css`
          align-self: flex-end;
        `}
        trigger={<Icon icon="Menu" size={50} />}
      >
        <FullScreenMenu.Item label="My Profile" href="/my-profile" />
        <FullScreenMenu.Item label="Collections" href="/collections" />
        <FullScreenMenu.Item label="Cards" href="/cards" />
        {isAdmin(user) && <FullScreenMenu.Item label="Users" href="/users" />}
        <FullScreenMenu.Item label="Logout" href="api/auth/logout" />
      </FullScreenMenu>
    </header>
  );
};

export default Header;
