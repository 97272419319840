import { UserProfile } from '@auth0/nextjs-auth0';
import ADMIN_ROUTES from './adminRoutes';
import { Permissions } from './types';
export const isAdmin = (
  user?: UserProfile & { 'https://www.offpiste.no/permissions'?: Permissions },
) => {
  if (
    user &&
    user['https://www.offpiste.no/permissions'] &&
    user['https://www.offpiste.no/permissions'].admin
  ) {
    return true;
  }
  return false;
};
export const hasAccess = (
  route: string,
  slug?: string | string[],
  docId?: string,
  permissions?: Permissions,
) => {
  if (!permissions) {
    return false;
  }
  if (permissions.admin) {
    return true;
  }
  if (permissions.opUser && ADMIN_ROUTES.indexOf(route) === -1) {
    return true;
  }
  if (
    permissions.canRead &&
    permissions.canRead.indexOf(route) > -1 &&
    !(permissions.blocked && permissions.blocked.indexOf(route) > -1)
  ) {
    return true;
  }
  if (slug && permissions.canRead && permissions.canRead?.indexOf(`${route}/${slug}`) > -1) {
    return true;
  }
  if (docId && permissions.canRead && permissions.canRead?.indexOf(docId) > -1) {
    return true;
  }
  return false;
};
